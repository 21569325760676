import React, { useState, useEffect } from 'react'
import { connect } from 'react-redux';
import { bindActionCreators } from "redux";
import { toast } from 'react-toastify';
import moment from 'moment'
import { Box } from "@material-ui/core";
import Pagination from "@material-ui/lab/Pagination";
import PaginationSetting from '../../constants/pagination';
import { getAllEnterpriseUsersDetails, getExportAllUsersByEnterprise } from '../../actions/userDetails';
import { useLocation } from 'react-router-dom';


const UserDetails = (props) => {
    document.title = "UsersDetails";
    const location = useLocation();
    const enterpriseId = location?.state?.enterpriseId;
    const [pagination, setPagination] = useState(PaginationSetting)
    const [usersDetails, setUsersDetails] = useState([])
    const [loading, setLoading] = useState(false);
    const [searchInput, setSearchInput] = useState('');
    const [page, setPage] = useState(1);

    const getUsersDetails = (clearSearch = false) => {
        let params = {
            id: enterpriseId,
            token: props.auth.token,
            page: page,
            limit: pagination.recordsPerPage
        }

        if (!clearSearch && searchInput && searchInput != '') {
            params = { ...params, search: searchInput, page: 1, };
        }
        setLoading(true);
        props.getAllUsersDetails(params, (res) => {
            setLoading(false);
            if (res.statusCode == 200 || res.statusCode == 201) {
                setUsersDetails(res.data.users)
                setPagination((prevState) => ({ ...prevState, totalPages: res.data.total_pages, totalRecords: res.data.total }));

            }
            else {
                setUsersDetails([]);
                setPagination((prevState) => ({ ...prevState, totalPages: 0, totalRecords: 0 }));
                if (res.statusCode !== 404) {
                    toast.error(res.message);
                }
            }
        },
            (err) => {
                setLoading(false);
                toast.error(err.message);
            })
    }


    const onChangeSearchInput = (searchValue, isClearSearch = false) => {
        setSearchInput(searchValue);
    }

    const exportAllUsers = () => {
        let params = {
            id: enterpriseId,
            token: props.auth.token,
        }
        setLoading(true);
        props.getExportAllUsers(params, (res) => {
            setLoading(false);
            if (res.statusCode == 200 || res.statusCode == 201) {
                fromateeddata(res?.data)
            }
            else {
                if (res.statusCode !== 404) {
                    toast.error(res.message);
                }
            }
        },
            (err) => {
                setLoading(false);
                toast.error(err.message);
            })

    }

    const headings = ['SrNo', 'Firstname', 'Lastname', 'Email', 'Phone No', 'Enterprise Code', 'Last Usage'];


    // new formatted data 
    const fromateeddata = (data) => {
        const formatted = [];

        data.map((item, index) => {
            const newdata = {}
            newdata.sno = index + 1;
            newdata.firstname = item?.firstname;
            newdata.lastname = item?.lastname;
            newdata.email = item?.user?.email;
            newdata.phone_no = item?.phone_no;
            newdata.enterprisecode = item?.enterprise?.subscription_code ;
            newdata.lastusage = item?.user?.api_tokens.length ? moment(item?.user?.api_tokens[0]?.updated_at).format("YYYY-MM-DD") : "---"

            formatted.push(newdata)
        })
      
        downloadCSV(formatted, headings)

    }

    // download csv file
    const downloadCSV = (data, headings, filename) => {
        const csvContent = "data:text/csv;charset=utf-8,"
            + [headings.join(','), ...data.map(row => Object.values(row).join(','))].join('\n');
        const encodedUri = encodeURI(csvContent);
        const link = document.createElement("a");
        link.setAttribute("href", encodedUri);
        link.setAttribute("download", filename || "data.csv");
        document.body.appendChild(link);
        link.click();
        document.body.removeChild(link);
    };

    useEffect(() => {
        getUsersDetails();
    }, [page, searchInput])

    return (

        <>
            <div className="content-wrapper">
                <div className="content-header">
                    <div className="container-fluid">
                        <div className="row mb-2">
                            <div className="col-sm-12 col-md-6">
                                <h1 className="m-0">User-Details</h1>
                            </div>
                            <div className="col-sm-12 col-md-6 text-end">
                                <button className='exportBtn_' onClick={() => exportAllUsers()}> Export</button>
                                <form className='inputSearchForm'>
                                    <div className='input-group me-2'>
                                        <input type="text" className="form-control rounded"
                                            onChange={(e) => onChangeSearchInput(e.target.value)}
                                            placeholder="Search" aria-label="Search" aria-describedby="search-addon" />

                                    </div>
                                    <button className='searchBtn' type="button"
                                        onClick={() => { return searchInput ? getUsersDetails() : '' }}
                                    >Search</button>
                                </form>
                            </div>
                        </div>
                    </div>
                </div>

                {/* Main content */}
                <section className="content pb-3">
                    <div className="row">
                        <div className="col-12">
                            <div className="card">
                                <div className="card-body p-0">
                                    {loading ?
                                        <div className="row p-5 mt-5 mb-5">
                                            <div className="col-12 text-center">
                                                <div className="spinner-grow text-success" role="status"></div>
                                            </div>
                                        </div>
                                        :
                                        <>
                                            <table style={{ width: '100%' }} className="table table-striped">
                                                <thead style={{ fontSize: '15px' }}>
                                                    <tr>
                                                        <th style={{ borderTop: '0px' }} className='pl-3'>Sr. No</th>
                                                        <th style={{ borderTop: '0px' }} className='pl-3'>First Name</th>
                                                        <th style={{ borderTop: '0px' }} className='pl-3'>Last Name</th>
                                                        <th style={{ borderTop: '0px' }} className='pl-3'>Email</th>
                                                        <th style={{ borderTop: '0px' }} className='pl-3'>Phone No</th>
                                                        <th style={{ borderTop: '0px' }} className='pl-3'>Enterprise Code</th>
                                                        <th style={{ borderTop: '0px' }} className='pl-3'>Last Usage</th>
                                                    </tr>
                                                </thead>
                                                <tbody style={{ fontSize: '15px' }}>
                                                    {usersDetails && usersDetails.length > 0 ?
                                                        <>
                                                            {usersDetails.map((value, index) =>
                                                                <tr key={index} >
                                                                    <td className='text-center'>{(page - 1) * pagination.recordsPerPage + index + 1}</td>
                                                                    <td>{value?.firstname.length > 50 ? value?.firstname.substring(0, 50) + " ..." : value?.firstname}</td>
                                                                    <td>{value?.lastname.length > 50 ? value?.lastname.substring(0, 50) + " ..." : value?.lastname}</td>
                                                                    <td>{value?.user?.email ? value?.user?.email : "--"}</td>
                                                                    <td>{value?.phone_no ? value?.phone_no : "--"}</td>
                                                                    <td>{value?.enterprise?.subscription_code ? value?.enterprise?.subscription_code : "--"}</td>
                                                                    <td>{value?.user?.api_tokens.length ? moment(value?.user?.api_tokens[0]?.updated_at).format("YYYY-MM-DD") : "--"}</td>
                                                                </tr>
                                                            )
                                                            }
                                                        </>
                                                        :
                                                        <tr>
                                                            <td colSpan='8' style={{ textAlign: 'center' }}>No Data Found</td>
                                                        </tr>
                                                    }
                                                </tbody>
                                            </table>

                                            {pagination.totalRecords > pagination.recordsPerPage &&
                                                <Box py={3} display="flex" justifyContent="center">
                                                    <Pagination
                                                        //count={totalPages}
                                                        key={page}
                                                        //count={ totalPages || 0 }
                                                        count={pagination.totalPages ? pagination.totalPages : "" || 0}
                                                        color="secondary"
                                                        variant="outlined"
                                                        onChange={(e, value) => setPage(value)}
                                                        defaultPage={page}
                                                    />
                                                </Box>
                                            }
                                        </>
                                    }
                                </div>
                            </div>
                        </div>
                    </div>
                </section>
            </div>
        </>
    )
}

const mapStateToProps = state => ({
    auth: state.auth
});
const mapDispatchToProps = dispatch => ({
    getAllUsersDetails: bindActionCreators(getAllEnterpriseUsersDetails, dispatch),
    getExportAllUsers: bindActionCreators(getExportAllUsersByEnterprise, dispatch)
});
export default connect(mapStateToProps, mapDispatchToProps)(UserDetails);