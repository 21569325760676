import { Switch, Route } from 'react-router-dom';

import * as Layouts from './../app/layouts';
import * as Containers from './../app/containers';

function Router() {

  return (
    <Switch>
      {/* Auth Routes*/}
      <Layouts.NonPrivateRoute exact path="/login" component={Containers.Login} />
      <Layouts.NonPrivateRoute exact path="/forgotPassword" component={Containers.ForgotPassword} />
      <Layouts.NonPrivateRoute exact path="/resetPassword/:resetPasswordToken" component={Containers.ResetPassword} />

      <Layouts.PrivateRoute exact path="/" component={Containers.Dashboard} />
      <Layouts.PrivateRoute exact path="/careRole" component={Containers.CareRole} />
      <Layouts.PrivateRoute exact path="/user" component={Containers.User} />
      <Layouts.PrivateRoute exact path="/medicalConditions" component={Containers.MedicalConditions} />
      <Layouts.PrivateRoute exact path="/lockBoxes" component={Containers.LockBoxes} />
      <Layouts.PrivateRoute exact path="/resources" component={Containers.Resources} />
      <Layouts.PrivateRoute exact path="/dosageTypes" component={Containers.DosageTypes} />
      <Layouts.PrivateRoute exact path="/categories" component={Containers.Categories} />
      <Layouts.PrivateRoute exact path="/tags" component={Containers.Tags} />
      <Layouts.PrivateRoute exact path="/medlist" component={Containers.Medlist} />
      <Layouts.PrivateRoute exact path="/settings" component={Containers.Settings} />
      <Layouts.PrivateRoute exact path="/enterprises" component={Containers.Enterprises} />
      <Layouts.PrivateRoute exact path="/userDetails" component={Containers.UserDetails} />


      
      <Route component={Containers.NotFound} />
    </Switch>

  );
}

export default Router;