if (process.env.NODE_ENV === "production") {

    module.exports = {
   
       // Client's URL
       AUTH_API_URL: "https://shepherduser-uat.itechnolabs.tech:6001/api/v1",
       AUTH_API_BASE_URL: "https://shepherduser-uat.itechnolabs.tech:6001",
       API_URL:"https://shepherdapp.itechnolabs.tech/api/v1",
       BASE_URL:"https://shepherdapp.itechnolabs.tech",
       USER_PORTAL_URL: "https://uat-shepherdapp-frontend.itechnolabs.tech"
   
       // Development's URL
      //  AUTH_API_URL: "https://usersherperd.itechnolabs.tech/api/v1",
      //  AUTH_API_BASE_URL: "https://usersherperd.itechnolabs.tech",
      //  API_URL:"https://sheperdstagging.itechnolabs.tech/api/v1",
      //  BASE_URL:"https://sheperdstagging.itechnolabs.tech",
      // USER_PORTAL_URL: "https://shepherdapp-frontend.itechnolabs.tech"
     };
   
   } else {
   
    module.exports = {
   
      // Local's URL
      // AUTH_API_URL: "http://localhost:8000/api/v1",
      // AUTH_API_BASE_URL: "http://localhost:8000",
      // API_URL: 'http://localhost:7500/api/v1',
      // BASE_URL:"http://localhost:7500",
      // USER_PORTAL_URL: "http://localhost:3000"

      // Development's URL
      // AUTH_API_URL: "https://usersherperd.itechnolabs.tech/api/v1",
      // AUTH_API_BASE_URL: "https://usersherperd.itechnolabs.tech",
      // API_URL: 'https://sheperdstagging.itechnolabs.tech/api/v1',
      // BASE_URL:"https://sheperdstagging.itechnolabs.tech",
      // USER_PORTAL_URL: "https://shepherdapp-frontend.itechnolabs.tech"
   
      // Client's URL
      AUTH_API_URL: "https://shepherduser-uat.itechnolabs.tech:6001/api/v1",
      AUTH_API_BASE_URL: "https://shepherduser-uat.itechnolabs.tech:6001",
      API_URL: 'https://shepherdapp.itechnolabs.tech/api/v1',
      BASE_URL:"https://shepherdapp.itechnolabs.tech",
      USER_PORTAL_URL: "https://uat-shepherdapp-frontend.itechnolabs.tech"
  
    };
   
   }
   